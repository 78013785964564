import { Theme } from "@mui/material/styles";

const messageCenterMessageStyle = {
  messageContainer: (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 1rem 1rem 0.5rem",
    },
    margin: "0 1rem 1rem 1rem",
    width: "90%",
    color: "black",
  }),
  messageBubble: {
    padding: "1rem",
    borderRadius: "1rem",
    wordBreak: "break-word",
    hyphens: "auto",
  },
  ownMessageContainer: { marginLeft: "10%" },
  ownMessageText: {
    overflowWrap: "break-word",
    margin: "2rem 0",
    color: "text.primary",
  },
  otherMessageContainer: {},
  ownMessageBubble: (theme: Theme) => ({
    ...(theme.palette.mode === "dark"
      ? {
          backgroundColor: "background.medium",
        }
      : {
          backgroundColor: "#c9e1e1",
        }),
  }),
  otherMessageBubble: (theme: Theme) => ({
    ...(theme.palette.mode === "dark"
      ? {
          backgroundColor: "#4D6666",
        }
      : {
          backgroundColor: "#F4F4F4",
        }),
  }),
  readBox: { textAlign: "right", paddingRight: "1rem" },
  customerFeedbackBox: { padding: "1rem" },
};
export default messageCenterMessageStyle;
